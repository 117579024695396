<template>
  <div id="settings">
    <v-container>
      <v-progress-circular
        indeterminate
        color="primary"
        v-if="dialog.loading"
      />
      <confirm-dialog
        ref="confirmDialog"
        v-model="dialog.display"
        :title="$t('settings.data.confirmDeleteDataTitle')"
        :body="$t('settings.data.confirmDeleteDataText')"
        :loading="dialog.loading"
        :right-button-text="$t('general.buttons.validate')"
        :persistent="true"
        @cancel="dialog.display = false"
        @valid="onValidDeleteData"
      />
      <v-card>
        <v-tabs v-model="tab" left show-arrows>
          <v-tab href="#profile">
            {{ $t('title.settings.profile') }}
          </v-tab>
          <v-tab href="#language">
            {{ $t('title.settings.language') }}
          </v-tab>
          <v-tab href="#saved">
            {{ $t('title.settings.saved') }}
          </v-tab>
          <v-tab-item value="profile">
            <v-card flat>
              <v-card-text>
                <profile-component/>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="language">
            <v-card flat>
              <v-card-text>
                <v-radio-group
                  v-model="userLocale.settings.language"
                  @change="updateSetting('language')"
                  :mandatory="true"
                  :label="$t('settings.language.title')"
                >
                  <v-radio
                    :label="$t('settings.language.fr')"
                    value="fr"
                  ></v-radio>
                  <v-radio
                    :label="$t('settings.language.en')"
                    value="en"
                  ></v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="saved">
            <v-card flat>
              <v-card-text>
                <v-switch
                  v-model="userLocale.settings.rememberSelected"
                  inset
                  @change="updateSetting('rememberSelected')"
                  :label="rememberData"
                ></v-switch>
              </v-card-text>
            </v-card>
            <v-card flat>
              <v-card-text>
                <v-btn color="red" @click="dialog.display = true">
                  {{ $t('settings.data.deleteAll') }}
                </v-btn>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'settings',
  data: () => ({
    tab: null,
    dialog: {
      display: false,
      loading: false,
    },
    userLocale: {
      id: null,
      settings: {
        language: 'fr',
      },
    },
  }),
  components: {
    ConfirmDialog: () => import('@/components/Template/ConfirmDialog'),
    ProfileComponent: () => import('@/components/Template/Profile'),
  },
  methods: {
    ...mapActions(['displaySnackbar', 'loadUser', 'updateUser']),
    updateSetting: function(settingName) {
      this.updateUser({
        user: this.userConnected,
        data: { 'settings': this.userLocale.settings },
      }).then(() => {
        if (settingName === 'language' && this.userLocale.settings.language) {
          this.$i18n.locale = this.userLocale.settings.language
          this.$vuetify.lang.current = this.userLocale.settings.language
        }
      })
    },
    onValidDeleteData() {
      for (var attr in localStorage) {
        if (!attr.startsWith('JWT_')) {
          localStorage.removeItem(attr)
        }
      }
      this.dialog.display = false
      this.displaySnackbar({
        status: true,
        type: 'info',
        text: this.$t('settings.alert.confirmRemoval'),
      })
    },
  },
  computed: {
    ...mapGetters(['userConnected']),
    rememberData() {
      return this.userLocale.settings.rememberSelected
        ? this.$t('settings.data.remember')
        : this.$t('settings.data.rememberNot')
    },
  },
  created() {
    this.userLocale.id = this.userConnected.id
    if (this.userConnected.settings !== null) {
      this.userLocale.settings = this.userConnected.settings
    }
  },
}
</script>
